import merge from "lodash/merge";
import get from "lodash/get";

export const primaryButtonStyles = _theme => {
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.primaryButton"))};
  return {
    root: {
      fontSize: theme.v2.typography.sizing.l.lg,
      fontFamily: theme.v2.typography.bodyText1.family,
      border: 0,
      borderRadius: theme.v2.decoration.border.radius.default,
      fontWeight: theme.v2.typography.bodyText1.weight.heavy,
      textTransform: "capitalize",
      width: "100%",
    },
    primary: {
      backgroundColor: theme.v2.color.primary,
      color: theme.v2.color.onPrimary,
    },
    large: {
      padding: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
      height: "50px",
    },
  };
};
